import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './components/MainComponent';
import ScrollToTop from './components/elements/ScrollToTop';

function App() {
    return (
        <BrowserRouter>
            <ScrollToTop>
                <div className="App">
                    <Main />
                </div>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
