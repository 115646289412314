import React, { Component } from 'react';
import { Nav, Navbar, NavItem, NavLink, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { Link } from 'react-router-dom';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false
        }

        this.toggleNav = this.toggleNav.bind(this);
        this.closeNav = this.closeNav.bind(this);
    }

    toggleNav() {
        this.setState({ isNavOpen: !this.state.isNavOpen });
    }

    closeNav() {
        this.setState({ isNavOpen: false });
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="header-text">
                        <Link to="/" style={{ textDecoration: 'inherit', color: 'inherit'}}>
                            <p className="header-main-title">
                                <span className="header-main-title__first-letter">O</span>N
                                <span className="header-main-title__first-letter"> F</span>RAME</p>
                        </Link>
                        <p className="header-main-text d-none d-md-block">Photographer | Traveler</p>
                    </div>

                    <Navbar light expand="md">
                        <NavbarBrand className="d-block d-md-none" href="#">
                            <span className="header-main-text" style={{ fontSize: '0.85em'}}>Photographer | Traveler</span>
                        </NavbarBrand>
                        <NavbarToggler onClick={ this.toggleNav } />
                        <Collapse isOpen={ this.state.isNavOpen } navbar >
                            <Nav navbar className="m-auto">
                                <NavItem>
                                    <NavLink tag={ Link } to="/" onClick={ this.closeNav }>Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/cities" onClick={ this.closeNav }>Cities</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/nature" onClick={ this.closeNav }>Nature</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/about" onClick={ this.closeNav }>About Me</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={ Link } to="/contact" onClick={ this.closeNav }>Contact</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>
            </div>
        );
    }
}

export default Header;
