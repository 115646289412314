import React from 'react';
import RenderGallery from './elements/RenderGallery';

import NaturePhotos from '../photos/nature';

function Nature(props) {

    return (
        <div style={{ marginBottom: '80px' }}>
            <div className="container">
                <div className="component-top">
                    <div className="col-12 col-md-10 col-lg-7 m-auto">
                        <p className="text-title">Nature Gallery</p>
                        <div className="text-description" style={{ margin: '40px 0px'}}>
                            <p className="text-galleries-quote">“Photography is like exploring a new dimension, only I can go there but I can show you where I've been.”</p>
                            <p className="text-galleries-author">- Destin Sparks</p>
                        </div>
                    </div>
                </div>
            </div>

            <RenderGallery galleries={ NaturePhotos } />
        </div>
    )

}

export default Nature;
