import React from 'react';

const DelayLoading = ({ target, onLoading, threshold = 0, rootMargin = "0px 0px 600px 0px" }) => {

    React.useEffect(() => {
        const observer = new IntersectionObserver(onLoading, { rootMargin, threshold });
        const current = target.current;

        observer.observe(current);

        return () => {
            observer.unobserve(current);
        }
    })
}


export default DelayLoading;