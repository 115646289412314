import React from 'react';
import DelayLoading from './DelayLoading';

function ImageLoader(props) {
    const imageRef = React.useRef();
    const [ isVisible, setIsVisible ] = React.useState(false);
    const [ isLoaded, setIsLoaded ] = React.useState(false);

    DelayLoading({
        target: imageRef,
        onLoading: ([{ isIntersecting }], observer) => {
            if (isIntersecting) {
                setIsVisible(true)
                observer.unobserve(imageRef.current);
            }
        }
    });

    return (
        <div id='div-image' ref={ imageRef } >
            <div id="div-image-main" style={{ paddingBottom: `${props.aspectRatio}%` }}>
                { isVisible &&
                    <React.Fragment>
                        <img 
                            onLoad={() => setIsLoaded(true) }
                            src={ props.src } alt="Landscape" className="full"
                            onClick={() => props.onClickListener() } />
                        <img src={ props.thumb } alt="Landscape" className="blur"
                            style={{ visibility: isLoaded ? "hidden" : "visible" }} />
                    </React.Fragment>
                }
            </div>
        </div>
    )
}

export default ImageLoader;
