import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

let config = {
    apiKey: "AIzaSyCvIFryPxA0ZuJeYIhoUXoXpHbuPMhxXLU",
    authDomain: "sarriastudios-8d826.firebaseapp.com",
    databaseURL: "https://sarriastudios-8d826.firebaseio.com",
    projectId: "sarriastudios-8d826",
    storageBucket: "sarriastudios-8d826.appspot.com",
    messagingSenderId: "474937559301",
    appId: "1:474937559301:web:123078ad454b6921668f6c",
    measurementId: "G-BQ0GCPY2XX"
};

class Firebase {
    constructor() {
        firebase.initializeApp(config);

        this.storage = firebase.storage();
    }

    getStorage() {
        return this.storage;
    }
}

export default Firebase;
