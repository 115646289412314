import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import Home from './HomeComponent';
import AboutMe from './AboutMeComponent';
import ContactMe from './ContactMeComponent';
import Nature from './NatureComponent';
import Cities from './CitiesComponent';

function Main(props) {

    return (
        <div>
            <Header />
            <Switch>
                <Route exact strict path="(.*//+.*)" render={({ location }) => <Redirect to={location.pathname.replace(/\/\/+/g, '/')} />} />
                <Route path="/contact" component={ ContactMe } />
                <Route path="/nature" component={ Nature } />
                <Route path="/cities" component={ Cities } />
                <Route path="/about" component={ AboutMe } />

                <Route path="/" component={ Home } />
                <Redirect to="/" />
            </Switch>
            <Footer />
        </div>
    );
}

export default withRouter(Main);
