import React from 'react';
import { Link } from 'react-router-dom';

import MultiImage from './elements/MultiImage';

function Home(props) {
    const [width, setWidth] = React.useState(window.innerWidth);

    React.useEffect(() => {
        const resizeListener = () => setWidth(window.innerWidth);
        window.addEventListener("resize", resizeListener);

        return () => window.removeEventListener("resize", resizeListener);
    }, []);

    return (
        <div>
            <div className="image-top" style={{ marginTop: '10px' }}>
                <img className="header-top-image" width="100%" height="550px"
                    src="/assets/home_photo.jpg" alt="Valley with lake" />
                <div className="image-centered-text" >
                    <p>You don't take a photograph<br/>You make it</p>
                </div>
            </div>

            <div className="container">
                <div className="col-12 col-md-10 m-auto">
                    <div className="m-auto">
                        <p className="home-quote">"He sees in black & white,<br/>Imagines in grey,<br/>But loves in color."</p>
                    </div>
                    <Link to="/cities" style={{ textDecoration: 'inherit', color: 'inherit'}}>
                        <RenderPortraits width={ width } />
                    </Link>
                    <Link to="/nature" style={{ textDecoration: 'inherit', color: 'inherit'}}>
                        <RenderLandscapes width={ width } />
                    </Link>
                </div>
            </div>
        </div>
    );
}

function RenderPortraits(props) {
    const title = "Cities Gallery";
    const quote = "\"There is one thing the photograph must contain, the humanity of the moment.\"";
    const author = "- Robert Frank";
    const images = [
        "/assets/lowRes/porto4.jpg",
        "/assets/lowRes/lisbon1.jpg",
        "/assets/lowRes/cienfuegos1.jpg"
    ];

    if (props.width > 720) {
        return (
            <div>
                <div className="card-gallery">
                    <div className="row">
                        <div className="col-6">
                            <div style={{ overflow: 'hidden' }} >
                                <MultiImage images={ images } alt={ title } height="250px" width="100%" />
                            </div>
                        </div>
                        <div className="col-6 m-auto">
                            <div className="gallery-text">
                                <p className="text-galleries-title">{ title }</p>
                                <p className="text-galleries-quote">{ quote }</p>
                                <p className="text-galleries-author">{ author }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (<RenderPhoneCard images={ images } title={ title } quote={ quote } author={ author } />);
    }
}

function RenderLandscapes(props) {
    const title = "Nature Gallery";
    const quote = "\"Landscape photography is the supreme test of the photographer - and often the supreme disappointment.\"";
    const author = "- Ansel Adams";
    const images = [
        "/assets/lowRes/cinque3.jpg",
        "/assets/lowRes/highlands1.jpg",
        "/assets/lowRes/highlands3.jpg",
        "/assets/lowRes/picos2.jpg",
        "/assets/lowRes/ocean1.jpg"
    ];

    if (props.width > 720) {
        return (
            <div>
                <div className="card-gallery">
                    <div className="row">
                        <div className="col-6 m-auto">
                            <div className="gallery-text">
                                <p className="text-galleries-title">{ title }</p>
                                <p className="text-galleries-quote">{ quote }</p>
                                <p className="text-galleries-author">{ author }</p>
                            </div>
                        </div>
                        <div className="col-6">
                            <div style={{ overflow: 'hidden' }} >
                                <MultiImage images={ images } alt={ title } height="250px" width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<RenderPhoneCard images={ images } title={ title } quote={ quote } author={ author } />);
    }
}

function RenderPhoneCard(props) {
    return (
        <div>
            <div className="card-gallery">
                <div className="col-12" style={{ margin: '0px', padding: '0px'}}>
                    <div style={{ overflow: 'hidden' }}>
                        <MultiImage images={ props.images } alt={ props.title } height="250px" width="100%" />
                    </div>
                </div>
                <div className="col-12 m-auto">
                    <div className="gallery-text">
                        <p className="text-galleries-title">{ props.title }</p>
                        <p className="text-galleries-quote">{ props.quote }</p>
                        <p className="text-galleries-author">{ props.author }</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
