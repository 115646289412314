import React from 'react';

import RenderGallery from './elements/RenderGallery';
import CitiesPhotos from '../photos/cities';

function Cities(props) {

    return (
        <div style={{ marginBottom: '80px' }}>
            <div className="container">
                <div className="component-top">
                    <div className="col-12 col-md-10 col-lg-7 m-auto">
                        <p className="text-title">Cities Gallery</p>
                        <div className="text-description" style={{ margin: '40px 0px'}}>
                            <p className="text-galleries-quote">“Photography has the capacity to provide imagres of man and his environment that are both works of art and moments in history.”</p>
                            <p className="text-galleries-author">- Cornell Capa</p>
                        </div>
                    </div>
                </div>
            </div>

            <RenderGallery galleries={ CitiesPhotos } />
        </div>
    );
}

export default Cities;
