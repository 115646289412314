import React, { Component } from 'react';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { LocalForm, Control, Errors, actions } from 'react-redux-form';

import FirebaseContext from './Firebase/FirebaseContext';

const required = (val) => val && val.length;
const minLength = (len) => (val) => !(val) || (val.length >= len);
const validEmail = (val) => !(val) || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val);

class ContactMe extends Component {

    constructor (props) {
        super(props);

        this.state = {
            isModalOpen: false
        };

        this.toggleModal = this.toggleModal.bind(this);
        this.handleContactSubmit = this.handleContactSubmit.bind(this);
    }

    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen});
    }

    handleContactSubmit(values, firebase) {
        var request = JSON.stringify(values).toString();
        var fileName = Math.round(Math.random() * 10000000000000);

        var storageRef = firebase.getStorage().ref();
        var requestsRef = storageRef.child('requests');
        requestsRef.child(fileName.toString() + '.txt')
            .putString(request)
            .then(snapshot => {
                this.toggleModal();
                this.formDispatch(actions.reset('request'));

            }, err => {
                this.handleContactSubmit(values);
            })
            .catch(err => {
                console.log(err.message)
            });
    }

    render() {
        const RenderForm = ({ firebase }) => {
            return (
                <LocalForm model="request" onSubmit={(values) => this.handleContactSubmit(values, firebase)}
                        getDispatch={(dispatch) => this.formDispatch = dispatch} >
                    <div style={{ textAlign: 'left' }} >
                        <div className="form-group">
                            <Label className="col-12 form-label" htmlFor="name">Name *</Label>
                            <div className="col-12">
                                <Control.text model=".name" id="name"
                                    className="form-control" validators={{required, minLength: minLength(3)}} />
                                <Errors
                                    className="text-danger" model=".name" show="touched"
                                    messages={{
                                        required: "Required field.",
                                        minLength: "Name must be greater than 2 characters."
                                    }} />
                            </div>
                        </div>
                        <div className="form-group">
                            <Label className="col-12 form-label" for="email">Email *</Label>
                            <div className="col-12">
                                <Control.text model=".email" id="email" name="email"
                                    className="form-control" validators={{required, validEmail}} />
                                <Errors
                                    className="text-danger" model=".email" show="touched"
                                    messages={{
                                        required: "Required field.",
                                        validEmail: "Invalid email address."
                                    }} />
                            </div>
                        </div>
                        <div className="form-group">
                            <Label className="col-12 form-label" for="message">Message *</Label>
                            <div className="col-12">
                                <Control.textarea model=".message" id="message" name="message" rows="3"
                                    className="form-control" validators={{ required }} />
                                <Errors
                                    className="text-danger" model=".message" show="touched"
                                    messages={{
                                        required: "Required field."
                                    }} />
                            </div>
                        </div>
                        <div className="form-group">
                            <Label className="col-12 form-label" for="requirements">What type of photography are you looking for? *</Label>
                            <div className="col-12">
                                <Control.textarea model=".requirements" id="requirements" name="requirements" rows="3"
                                    className="form-control" validators={{ required }} />
                                <Errors
                                    className="text-danger" model=".requirements" show="touched"
                                    messages={{
                                        required: "Required field."
                                    }} />
                            </div>
                        </div>
                    </div>
                    <Button outline color="secondary" className="button-submit shadow-none" type="submit">Submit</Button>
                </LocalForm>
            )
        }

        return (
            <div className="container">
                <div className="component-top">
                    <div className="col-12 col-md-10 col-lg-7 m-auto">
                        <p className="text-title">Contact Me</p>
                        <p className="text-description">I'd love to hear from you. If you have any questions or would like to book a session, please fill out the form below.</p>
                    </div>
                </div>

                <div className="col-12 col-md-10 col-lg-8 m-auto">
                    <FirebaseContext.Consumer>
                        {(firebase) => {
                            return( <RenderForm firebase={ firebase } /> )
                        }}
                    </FirebaseContext.Consumer>
                </div>
                <Modal isOpen={ this.state.isModalOpen } toggle={ this.toggleModal} centered>
                    <ModalHeader toggle={ this.toggleModal }>Request Sent Successfully!</ModalHeader>
                    <ModalBody toggle={ this.toggleModal }>
                        Your request is being reviewed.<br/> I will enter in contact with you soon.
                    </ModalBody>
                    <ModalFooter>
                          <Button outline color="secondary" onClick={ this.toggleModal }>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ContactMe;
