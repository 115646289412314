import React from 'react';

function Footer(props) {

    return (
        <div className="container">
            <div className="footer">
                <p><a href="mailto:info@onframe.photos" style={{ color: "inherit" }}>info@onframe.photos</a></p>
            </div>
        </div>
    );
}

export default Footer;
