import React, { Component } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.css';

import ImageLoader from './ImageLoader';

class GalleryCarousel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isAutoPlaying: false,
            isGoingBack: false,
            isGoingForward: false,
        };

        this.galleryId = "gallery" + props.title;
    }

    componentDidMount() {
        const gallery = new Viewer(document.getElementById(this.galleryId), {
            navbar: false,
            title: false,
            toolbar: {
                zoomIn: 0,
                zoomOut: 0,
                oneToOne: 0,
                reset: 0,
                prev: 2,
                play: 0,
                next: 2,
                rotateLeft: 0,
                rotateRight: 0,
                flipHorizontal: 0,
                flipVertical: 0
            },
            loading: true,
            loop: false,
            rotatable: false,
            tooltip: false
        });

        this.galleryViewer = gallery;
    }

    render() {
        const responsive = {
            ultraLarge: {
                breakpoint: { max: 4000, min: 2500 },
                items: 5,
                slidesToSlide: 5
            },
            superLarge: {
                breakpoint: { max: 2500, min: 1720 },
                items: 4,
                slidesToSlide: 4
            },
            large: {
                breakpoint: { max: 1720, min: 992 },
                items: 3,
                slidesToSlide: 3
            },
            medium: {
                breakpoint: { max: 992, min: 576 },
                items: 2,
                slidesToSlide: 2
            },
            small: {
                breakpoint: { max: 576, min: 0},
                items: 1,
                slidesToSlide: 1
            }
        };

        return (
            <div id="gallery-main-div">
                <Carousel responsive={ responsive }
                        swipeable={ window.innerWidth < 992 ? true : false }
                        draggable={ false }
                        keyBoardControl={ false } >
                    { this.props.images.map((image, pos) =>
                        <ImageLoader key={ pos } thumb={ image.thumb } src={ image.lowRes } aspectRatio={ this.props.aspectRatio }
                            onClickListener={() => this.galleryViewer.view(pos) } /> ) }
                </Carousel>

                <div id={ this.galleryId } style={{ display: 'none' }}>
                    <template>
                        <ul>
                            { this.props.images.map((image, pos) => <li key={ pos }><img src={ image.highRes } alt="Landscape" width="1px" /></li> ) }
                        </ul>
                    </template>
                </div>
            </div>
        );
    }
}

export default GalleryCarousel;
