import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';

function MultiImage(props) {

    const fadeProperties = {
        duration: 5000,
        transitionDuration: 2000,
        infinite: true,
        indicators: false,
        arrows: false
    };

    const imageSlide = props.images.map((image, pos) => {
        return(
            <React.Fragment>
                <div className="each-fade" key={ pos }>
                    <div className="image-container">
                        <img className="header-top-image" src={ image } alt={ props.alt } width={ props.width } height={ props.height }/>
                    </div>
                </div>
            </React.Fragment>
        );
    });

    return (
        <React.Fragment>
            <Fade {...fadeProperties} >
                { imageSlide }
            </Fade>
        </React.Fragment>
    );
}

export default MultiImage;
