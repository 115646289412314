import React from 'react';
import GalleryCarousel from './GalleryCarousel';

function RenderGallery(props) {

    return props.galleries.map((gallery, pos) => {
        return (
            <div style={{ marginBottom: '100px' }} key={ pos }>
                <div className="gallery-backgrounds" />
                <div className="container">
                    <div className="col-12 col-md-10 offset-md-1">
                        <h2 className="gallery-title">{ gallery.name }</h2>
                        <p className="gallery-country">{ gallery.country }</p>
                    </div>
                </div>
                <GalleryCarousel className="gallery-carousel" images={ gallery.images } title={ gallery.name } aspectRatio={ gallery.ar } />
            </div>
        );
    });
}

export default RenderGallery;
