import React from 'react';

function AboutMe(props) {

    return (
        <div>
            <div className="container">
                <div className="component-top">
                    <p className="text-title">About Me</p>
                </div>

                <div className="row" style={{ marginBottom: '10px' }}>
                    <div className="col-10 col-md-5 offset-1" >
                        <div className="image-top" style={{ height: '400px', marginBottom: '20px' }}>
                            <img className="img-center-crop" width="100%" height="100%"
                                src="/assets/profile.jpg" alt="Profile Picture" />
                        </div>
                    </div>
                    <div className="col-12 col-md-5">
                        <p className="text-description text-justify">
                            Hello, and welcome to my studio!
                            <br/><br/>
                            My name is Carlos Machado and I'm a enthusiast photographer born in Portugal.
                            <br/><br/>
                            Despite my professional background not being related to photography, over the last few years I
                            have developed an interest on the world of photography, and it has changed my life ever since.
                            <br/><br/>
                            I love finding and capturing that piece of scenery that includes all the magic of the place I'm in,
                            and I'm learning more and more about the subject everyday and looking for forward to improve my capabilities as a photographer.
                        </p>
                    </div>
                </div>

                <div className="row" style={{ marginBottom: '60px' }}>
                    <div className="col-12 col-md-10 m-auto">
                        <p className="text-description text-justify">
                            I’m really passionate about my work, based on moments and emotions.
                            I want to use this project to share it with others and inspire you to appreciate the
                            world and the beauty that surround us.
                        </p>
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: '60px' }}>
                <div className="image-top">
                    <img style={{ objectFit: "cover", objectPosition: "center" }} width="100%" height="500px"
                        src="/assets/about_landscape.jpg" alt="Vernazza at night." />
                    <div className="image-centered-text" >
                        <p style={{ fontStyle: "normal" }}>Photography is the story<br/>I fail to put in words.</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AboutMe;
